import { PrismicText, PrismicRichText } from "@prismicio/react";

/**
  * Column item
*/
const Column = ({ item }) => {
  return (
    <div className="col-12 col-md">
      <img className="feature--icon mb-5" src={item.image.url} alt={item.image.alt} />
      <PrismicRichText field={item.copy} />
    </div>
  );
};

/**
 * Column section slice component
 */
export const Columns = ({ slice }) => {
  const sectionClass = slice.slice_label
    ? `text-section-${slice.slice_label}`
    : "columns-section";

  return (
    <section className={`content-section ${sectionClass} alt--bg_true`}>
      <div className="container pb-5">
        <div className="row py-5">
          <div className="col">
            <h3 className="subtitle mb-5">
              <PrismicText field={slice.primary.subtitle} />
            </h3>
          </div>
        </div>
        <div className="row">
          {slice.items.map((item) => (
          <Column key={item.image.url} item={item} />
          ))}
        </div>
      </div>
    </section>
  );
};
