import { PrismicText, PrismicRichText } from "@prismicio/react";

/**
  * Callout item
*/
const Callout = ({ item }) => {
  return (
    <div className="col-12 col-md callout mb-3 mb-md-0">
      <img className="feature--icon_sm" src={item.icon.url} alt={item.icon.alt} />
      <PrismicRichText field={item.callout} />
    </div>
  );
};

/**
 * Split callout section slice component
 */
export const SplitCallout = ({ slice }) => {
  const sectionClass = slice.slice_label
    ? `text-section-${slice.slice_label}`
    : "split-callout-section";

  return (
    <section className={`content-section ${sectionClass} alt--bg_true`}>
      <div className="container">
        <div className="row pt-5">
          <div className="col">
            <h3 className="subtitle mb-5">
              <PrismicText field={slice.primary.subtitle} />
            </h3>
          </div>
        </div>
        <div className="row align-items-center pt-5">
          <div className="col-md-6 pt-5">
            <PrismicRichText field={slice.primary.left_copy} />
          </div>
          <div className="col-md-4 offset-md-2">
            <img className="img-fluid" src={slice.primary.right_image.url} alt={slice.primary.right_image.alt} />
          </div>
        </div>
        <div className="row mt-3 py-5 justify-content-between">
          {slice.items.map((item) => (
          <Callout key={item.icon.url} item={item} />
          ))}
        </div>
      </div>
    </section>
  );
};
