import React,{useState,useEffect} from 'react';
import { PrismicText, PrismicRichText } from "@prismicio/react";
import { getLatLngObj } from "tle.js";
import axios from 'axios';

/**
  * Feature item
*/
const FeatureItem = ({ item }) => {
  return (
    <div className="col-12 col-md feature mb-5 mb-md-0">
      <img className="feature--icon" src={item.icon.url} alt={item.icon.alt} />
      <PrismicRichText field={item.feature} />
    </div>
  );
};

/**
 * Ghost slice component
*/
export const Ghost = ({ slice }) => {
  const sectionClass = slice.slice_label
    ? `text-section-${slice.slice_label}`
    : "text-section-ghost";

    // get set the tle
    const tle = `GHOST-2
    1 56197U 23054V   23137.59983559  .00006553  00000+0  28451-3 0  9992
    2 56197  97.4068  32.9696 0013507 120.2658 239.9918 15.22572632  5527`;
    const latLonObj = getLatLngObj(tle);
    // the the coords
    const satLat = latLonObj.lat;
    const satLng = latLonObj.lng;

    // get location name
    const [location, setLocation] = useState("");

    useEffect(() => {
      const url = 'https://maps.googleapis.com/maps/api/geocode/json?latlng='+satLat+','+satLng+'&key=AIzaSyDi-PutDzjIBR9P4yxvQ8GSflJOYMjmujc';

      const fetchData = async () => {
        try {
          const response = await fetch(url);
          const json = await response.json();
          //console.log(json);
          //console.log(json.results[3].formatted_address);
          //const satLoc = json.results[3].formatted_address;
          setLocation(json.results[3].formatted_address);
        } catch (error) {
          console.log("error", error);
        }
      };

      fetchData();
    }, []);

  return (
    <section className={`content-section ${sectionClass}`} style={{ backgroundImage: 'url(/img/satellite-path.svg)' }}>
      <figure id="ghost">
        <img src={slice.primary.ghost_image.url} alt={slice.primary.ghost_image.alt} />
        <figcaption className="caption">{slice.primary.ghost_image.alt}</figcaption>
      </figure>
      <div className="container">
        <div className="row">
          <div className="col">
            <h4 className="detail mb-5">
              <PrismicText field={slice.primary.ghost_title} />
              <span className="sat--location">
                {
                  location ? (
                    <span>
                    Above {location}
                    </span>
                  ) :
                    <span>Above Water</span>
                }
                <br/><br/>LAT
                <span className="satLat px-1"
                  dangerouslySetInnerHTML={{
                    __html: satLat,
                    maxLength: 6,
                  }}
                ></span>
                <span className="breaker px-1">/ </span>LNG
                <span className="satLng px-1"
                  dangerouslySetInnerHTML={{
                    __html: satLng,
                    maxLength: 6,
                  }}
                ></span>
              </span>
            </h4>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col mt-5">
            <h3 className="subtitle my-5">
              <PrismicText field={slice.primary.subtitle} />
            </h3>
          </div>
        </div>
        <div className="row pb-5">
          <div className="col-md-10">
            <PrismicRichText field={slice.primary.copy} />
          </div>
        </div>
        <div className="row pt-5">
        {slice.items.map((item) => (
          <FeatureItem key={item.icon.url} item={item} />
        ))}
        </div>
      </div>
    </section>
  );
};
