import { PrismicText, PrismicRichText } from "@prismicio/react";

/**
  * Featured list item
*/
const Featured = ({ item }) => {
  return (
    <div className="col-12 col-md column-content">
      <h4 className="py-5">
        <PrismicText field={item.feature} />
      </h4>
      {
        item.callout && (
          <PrismicRichText field={item.callout} />
        )
      }
    </div>
  );
};

/**
 * Header with columns slice component
 */
export const HeaderColumns = ({ slice }) => (
  <section className="header-columns content-section alt--bg_true">
    <div className="container py-5">
      <div className="row py-5">
        <div className="col">
          <h3>
            <PrismicText field={slice.primary.header} />
          </h3>
        </div>
      </div>
      <div className="row">
        {slice.items.map((item) => (
        <Featured key={item.title} item={item} />
        ))}
      </div>
      <div className="row py-5">
        <div className="col mt-5 text-center">
          <a className="btn btn-secondary" href="https://jobs.lever.co/orbitalsidekick" target="_blank">
            See Openings
          </a>
        </div>
      </div>
    </div>
  </section>
);
