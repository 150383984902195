import { PrismicText } from "@prismicio/react";
import { Link } from "react-router-dom";


/**
  * Column item
*/
const Deep = ({ item }) => {

  // deep linking
  function scrollToId() {
    document.querySelector(`${((item.deep_link_slug || {})[0] || {}).text}`).scrollIntoView({behavior: 'smooth'});
  }

  return (
    <li>
      <Link to={((item.deep_link_slug || {})[0] || {}).text} onClick={scrollToId}>
        <PrismicText field={item.deep_link_label} />
      </Link>
    </li>
  );
};

/**
 * Deep link section slice component
 */
export const DeepLinks = ({ slice }) => {
  const sectionClass = slice.slice_label
    ? `text-section-${slice.slice_label}`
    : "deep-links";

  return (
    <section className={`content-section sticky-top ${sectionClass}`}>
      <div className="container my-3">
        <div className="row">
          <ul className="py-5">
            {slice.items.map((item) => (
            <Deep key={item.deep_link_url} item={item} />
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};
