import { PrismicText, PrismicLink } from "@prismicio/react";

/**
 * Homepage banner component
 */
export const HomepageBanner = ({ banner }) => (
  <section
    className="lead--in"
    style={{
      backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.4)), url(${banner.image.url})`,
    }}
  >
    <div className="container">
      <div className="row">
        <div className="col-md-10">
          <h1 className="banner-title">
            <PrismicText field={banner.title} />
          </h1>
          <p className="banner-description my-5">
            <PrismicText field={banner.tagline} />
          </p>
          <PrismicLink field={banner.button_link} className="btn btn-primary mb-3 mb-md-0">
            <PrismicText field={banner.button_label} />
          </PrismicLink>
          <PrismicLink field={banner.secondary_link} className="btn btn-primary ms-md-5">
            <PrismicText field={banner.secondary_label} />
          </PrismicLink>
        </div>
      </div>
    </div>
  </section>
);
