import { PrismicText, PrismicRichText } from "@prismicio/react";

/**
  * Feature item
*/
const Features = ({ item }) => {
  return (
    <div className="col-12 col-md mb-5 mb-md-0">
      <img className="feature--icon mb-5" src={item.feature_image.url} alt={item.feature_image.alt} />
      <PrismicRichText field={item.featured_copy} />
    </div>
  );
};

/**
 * Text section slice component
 */
export const ProductHighlight = ({ slice }) => {
  const hasIcon = slice.primary.icon.url;
  const sectionClass = slice.slice_label
    ? `text-section-${slice.slice_label}`
    : "product-highlight";

  return (
    <section className={`content-section ${sectionClass} alt--bg_true`}>
      <div className="container">
        <div className="row py-5">
          <div className="col-md-10">
            <h3 className="subtitle mb-5">
              <PrismicText field={slice.primary.subtitle} />
            </h3>
            <h3 className="mb-5">
              {
                hasIcon && (
                  <img className="img-icon" src={hasIcon} alt={slice.primary.icon.alt} />
                )
              }
              <PrismicText field={slice.primary.title} />
            </h3>
            {
              slice.primary.tagline && (
                <h3 className="mb-5">
                  <PrismicText field={slice.primary.tagline} />
                </h3>
              )
            }
          </div>
        </div>
        <div className="row my-5">
          {slice.items.map((item) => (
          <Features key={item.feature_image.url} item={item} />
          ))}
        </div>
      </div>
    </section>
  );
};
