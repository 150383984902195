import { PrismicText, PrismicRichText, PrismicLink } from "@prismicio/react";

/**
 * Footer block slice component
 */
export const FooterBlock = ({ slice }) => {
  const sectionClass = slice.slice_label
    ? `text-section-${slice.slice_label}`
    : "text-section-footer-block";

  return (
    <section className={`content-section ${sectionClass}`}>
      <div className="container mt-5">
        <div className="row">
          <div className="col">
            <h3 className="subtitle mb-5">
              <PrismicText field={slice.primary.subtitle} />
            </h3>
          </div>
        </div>
        <div className="row">
          <div className="col-md-8">
            <PrismicRichText field={slice.primary.copy} />
          </div>
        </div>
      </div>
      <div className="constrained">
        <div className="container-fluid">
          <div className="row py-5">
            <div className="col py-5">
              <PrismicLink field={slice.primary.footer_button_link} className="btn btn-primary btn-xxl btn-block">
                <PrismicText field={slice.primary.footer_button_label} />
              </PrismicLink>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
