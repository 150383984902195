import { PrismicText, PrismicRichText } from "@prismicio/react";

/**
 * Text section slice component
 */
export const TextSection = ({ slice }) => {
  const sectionClass = slice.slice_label
    ? `text-section-${slice.slice_label}`
    : "left-text-section";
  const sectionBg = slice.primary.alt_bg;

  const image1 = slice.primary.right_image.url;
  const image2 = slice.primary.secondary_right_image.url;

  return (
    <section className={`content-section ${sectionClass} alt--bg_${sectionBg}`}>
      <div className="container">
        <div className="row pt-5">
          <div className="col">
            <h3 className="subtitle mb-5">
              <PrismicText field={slice.primary.subtitle} />
            </h3>
          </div>
        </div>
        <div className="row align-items-center pt-5">
          <div className="col-md-4 offset-md-1 order-md-2">
            {
              image1 && (
              <figure>
                <img className="img-fluid" src={image1} alt={slice.primary.right_image.alt} />
              </figure>
              )
            }
            {
              image2 && (
              <figure>
                <img className="img-fluid" src={image2} alt={slice.primary.secondary_right_image.alt} />
              </figure>
              )
            }
          </div>
          <div className="col-md-7 pt-5 order-md-1">
            <PrismicRichText field={slice.primary.rich_text} />
          </div>
        </div>
      </div>
    </section>
  );
};
