import { PrismicLink, PrismicRichText, PrismicText } from "@prismicio/react";
import * as prismicH from "@prismicio/helpers";
import { Link } from "react-router-dom";

import { PostDate } from "./PostDate";

/**
 * Post list item component
 */
export const PostItem = ({ post }) => {

  return (
    <div className="col-md-4 post">
      {
        post.data.content.length > 0 &&
        <Link to={post.uid}>
          <figure
            className="cover--photo"
            style={{ backgroundImage:           `url(${post.data.featured_image.url})` }}
          />
        </Link>
      }
      {
        post.data.content.length === 0 &&
        <PrismicLink field={post.data.external_url}>
          <figure
            className="cover--photo"
            style={{ backgroundImage:           `url(${post.data.featured_image.url})` }}
          />
        </PrismicLink>
      }
      <PostDate date={post.data.date} />
      <p>
        <PrismicText field={post.data.title} />
      </p>
      {
        post.data.content.length > 0 &&
        <Link to={post.uid} className="read--more">
          Read More
        </Link>
      }
      {
        post.data.content.length === 0 &&
        <PrismicLink field={post.data.external_url} className="read--more ext">
          Read More
        </PrismicLink>
      }
    </div>
  );
};
