import { FullWidthImage } from "./FullWidthImage";
import { ImageGallery } from "./ImageGallery";
import { ImageHighlight } from "./ImageHighlight";
import { Quote } from "./Quote";
import { TextSection } from "./TextSection";
import { Ghost } from "./Ghost";
import { Logos } from "./Logos";
import { FooterBlock } from "./FooterBlock";
import { Heading } from "./Heading";
import { ProductHighlight } from "./ProductHighlight";
import { RightTextSection } from "./RightTextSection";
import { DualImage } from "./DualImage";
import { Columns } from "./Columns";
import { SplitCallout } from "./SplitCallout";
import { StandardText } from "./StandardText";
import { ListItems } from "./ListItems";
import { NumberedItems } from "./NumberedItems";
import { TeamMembers } from "./TeamMembers";
import { LinkList } from "./LinkList";
import { HeaderColumns } from "./HeaderColumns";
import { ImageComparison } from "./ImageComparison";
import { DeepLinks } from "./DeepLinks";

export const components = {
  full_width_image: FullWidthImage,
  image_gallery: ImageGallery,
  image_highlight: ImageHighlight,
  quote: Quote,
  text_section: TextSection,
  ghost: Ghost,
  logos: Logos,
  footer_block: FooterBlock,
  footer_block_1: FooterBlock,
  heading: Heading,
  product_highlight: ProductHighlight,
  right_text_section: RightTextSection,
  dual_image: DualImage,
  columns: Columns,
  split_callout: SplitCallout,
  just_text: StandardText,
  list_items: ListItems,
  numbered_list_items: NumberedItems,
  team_members: TeamMembers,
  link_list: LinkList,
  header_columns: HeaderColumns,
  image_comparison: ImageComparison,
  deep_links: DeepLinks
};
