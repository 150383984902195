import { PrismicRichText } from "@prismicio/react";

/**
 * Standard text slice component
 */
export const StandardText = ({ slice }) => (
  <section id={((slice.primary.deep_link_id || {})[0] || {}).text} className="standard-text content-section">
    <div className="container">
      <div className="row">
        <div className="col">
          <PrismicRichText field={slice.primary.content} />
        </div>
      </div>
    </div>
  </section>
);
