import React, { useState } from 'react';
import { PrismicText, PrismicLink } from "@prismicio/react";

/**
 * Menu link component
 */
const MenuLink = ({ menuLink }) => {
  return (
    <li className="nav-item">
      <PrismicLink className="nav-link px-4" field={menuLink.link}>
        <PrismicText field={menuLink.label} />
      </PrismicLink>
    </li>
  );
};

const DropLink = ({ dropLink }) => {
  return (
    <li>
      <PrismicLink className="dropdown-item" field={dropLink.link_url}>
        <PrismicText field={dropLink.link_label} />
      </PrismicLink>
    </li>
  );
};

/**
  * add class when at top of page
*/

// The debounce function receives our function as a parameter
const debounce = (fn) => {

  // This holds the requestAnimationFrame reference, so we can cancel it if we wish
  let frame;

  // The debounce function returns a new function that can receive a variable number of arguments
  return (...params) => {

    // If the frame variable has been defined, clear it now, and queue for next frame
    if (frame) {
      cancelAnimationFrame(frame);
    }

    // Queue our function call for the next frame
    frame = requestAnimationFrame(() => {

      // Call our function and pass any params we received
      fn(...params);
    });
  }
};

// Reads out the scroll position and stores it in the data attribute
// so we can use it in our stylesheets
const storeScroll = () => {
  document.documentElement.dataset.scroll = window.scrollY;
}

// Listen for new scroll events, here we debounce our `storeScroll` function
document.addEventListener('scroll', debounce(storeScroll), { passive: true });

// Update scroll position for first time
storeScroll();

/**
 * Site header/nav component
 */
export const Header = ({ menuDoc }) => {
  if (menuDoc) {

    // Toggle mobile nav class
    const [isActive, setActive] = useState("false");
    const ToggleClass = () => {
      setActive(!isActive);
     };

    const hasDropdowNav = menuDoc.data.dropdown_label;

    return (
      <header id="header" className={`container-fluid ${isActive ? "nav--closed" : "nav--open"}`}>
        <div id="desktopNav" className="row align-items-center px-3">
          <div className="col-lg-2 col-xl-3">
            <PrismicLink href="/">
              <div className="logo">
              </div>
            </PrismicLink>
          </div>
          <div className="col-lg-7 col-xl-6">
            <nav>
              <ul className="nav justify-content-center">
                {menuDoc.data.menu_links.map((menuLink) => (
                  <MenuLink menuLink={menuLink} key={menuLink.link.id} />
                ))}
                {
                  hasDropdowNav && (
                  <li className="nav-item dropdown">
                    <a className="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false">
                      {((menuDoc.data.dropdown_label || {})[0] || {}).text}
                    </a>
                    <ul className="dropdown-menu">
                      {menuDoc.data.dropdown_links.map((dropLink) => (
                        <DropLink dropLink={dropLink} key={dropLink.link_url.id} />
                      ))}
                    </ul>
                  </li>
                  )
                }
              </ul>
            </nav>
          </div>
          <div className="col-lg-3 text-end">
            <PrismicLink href="/contact" className="btn btn-outline-primary">
              Contact
            </PrismicLink>
          </div>
        </div>
        <div className="row align-items-center d-lg-none">
          <div className="col-10">
            <PrismicLink href="/">
              <div className="logo">
              </div>
            </PrismicLink>
          </div>
          <div className="col-2 text-right">
            <button onClick={ToggleClass} className="btn-hamburger float-end" type="button" data-bs-toggle="collapse" data-bs-target="#mobileNav" aria-expanded="false" aria-controls="mobileNav">
              <span></span>
              <span></span>
              <span></span>
            </button>
          </div>
        </div>
        <div id="mobileNav" className="row collapse">
          <div className="col">
            <nav>
              <ul className="nav flex-column text-center">
                {menuDoc.data.menu_links.map((menuLink) => (
                  <MenuLink menuLink={menuLink} key={menuLink.link.id} />
                ))}
                {
                  hasDropdowNav && (
                  <li className="nav-item">
                    <a className="nav-link" data-bs-toggle="dropdown" href="#" role="button" aria-expanded="false">
                      {((menuDoc.data.dropdown_label || {})[0] || {}).text}
                    </a>
                    <ul className="nav flex-column nav--subnav">
                      {menuDoc.data.dropdown_links.map((dropLink) => (
                        <DropLink dropLink={dropLink} key={dropLink.link_url.id} />
                      ))}
                    </ul>
                  </li>
                  )
                }
                <li className="nav-item">
                  <PrismicLink className="nav-link" href="/contact">
                    Contact
                  </PrismicLink>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </header>
    );
  }

  return null;
};
