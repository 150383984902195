import { useEffect } from "react";
import { SliceZone, useSinglePrismicDocument } from "@prismicio/react";

import { components } from "../slices";
import { Helmet } from "react-helmet";
import { Layout } from "../components/Layout";
import { HomepageBanner } from "../components/HomepageBanner";
import { NotFound } from "./NotFound";

/**
 * Website homepage component
 */
export const HomePage = () => {
  const [home, homeState] = useSinglePrismicDocument("homepage");
  const [menu, menuState] = useSinglePrismicDocument("menu");
  const [footermenu, footermenuState] = useSinglePrismicDocument("footer_menu");

  const notFound = homeState.state === "failed" || menuState.state === "failed" || footermenuState.state === "failed";

  useEffect(() => {
    if (homeState.state === "failed") {
      console.warn(
        "Homepage document was not found. Make sure it exists in your Prismic repository."
      );
    }
  }, [homeState.state]);

  // Return the page if a document was retrieved from Prismic
  if (home && menu && footermenu) {
    return (
      <Layout wrapperClass="homepage" menuDoc={menu} footerDoc={footermenu}>
        <Helmet>
          <title data-react-helmet="true">{((home.data.meta_title || {})[0] || {}).text}</title>
          <meta
            name="description"
            content={((home.data.meta_desc || {})[0] || {}).text}
            data-react-helmet="true"
          />
          <meta
            property="og:title"
            content={`${((home.data.meta_title || {})[0] || {}).text}`}
            data-react-helmet="true"
          />
          <meta
            property="og:description"
            content={((home.data.meta_desc || {})[0] || {}).text}
            data-react-helmet="true"
          />
          <link
            rel="canonical"
            href={`https://www.orbitalsidekick.com`}
            data-react-helmet="true"
          />
        </Helmet>
        <HomepageBanner banner={home.data.homepage_banner[0]} />
        <SliceZone slices={home.data.page_content} components={components} />
      </Layout>
    );
  } else if (notFound) {
    return <NotFound />;
  }

  return null;
};
