import { PrismicText, PrismicLink } from "@prismicio/react";
import { Link } from "react-router-dom";

/**
 * Menu link component
 */
const FooterLink = ({ footerLink }) => {
  return (
    <li>
      <PrismicLink field={footerLink.link}>
        <PrismicText field={footerLink.label} />
      </PrismicLink>
    </li>
  );
};

/**
 * Site footer component
 */
export const Footer = ({ footerDoc }) => {
  if (footerDoc) {
    return (
      <footer id="footer">
        <div className="constrained">
          <div className="container-fluid">
            <div className="row align-items-end">
              <div className="col-12 col-lg-6">
                <nav>
                  <ul className="navs">
                    {footerDoc.data.menu_links.map((footerLink) => (
                      <FooterLink footerLink={footerLink} key={footerLink.link.id} />
                    ))}
                  </ul>
                </nav>
              </div>
              <div className="col-8 col-lg-4 text-lg-end">
                  <ul className="social">
                    <li className="px-2">
                      <a href="mailto:info@orbitalsidekick.com">info@orbitalsidekick.com</a>
                    </li>
                    <li className="px-2">
                      <a href="https://twitter.com/OrbitalSidekick" target="_blank">
                        <svg viewBox="328 355 335 276" xmlns="http://www.w3.org/2000/svg">
                          <path d="M 630, 425 A 195, 195 0 0 1 331, 600 A 142, 142 0 0 0 428, 570 A  70,  70 0 0 1 370, 523 A  70,  70 0 0 0 401, 521 A  70,  70 0 0 1 344, 455 A  70,  70 0 0 0 372, 460 A  70,  70 0 0 1 354, 370 A 195, 195 0 0 0 495, 442 A  67,  67 0 0 1 611, 380 A 117, 117 0 0 0 654, 363 A  65,  65 0 0 1 623, 401 A 117, 117 0 0 0 662, 390 A  65,  65 0 0 1 630, 425 Z"/>
                        </svg>
                        <span className="visually-hidden">OSK on Twitter</span>
                      </a>
                    </li>
                    <li className="px-2">
                      <a href="https://www.linkedin.com/company/orbital-sidekick/" target="_blank">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                          <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"/>
                        </svg>
                        <span className="visually-hidden">OSK on LinkedIn</span>
                      </a>
                    </li>
                  </ul>
              </div>
              <div className="col-4 col-lg-2 text-end mt-5 mt-md-0">
                <img
                  className="footer-logo ms-3"
                  src="/img/logo-osk-gradient.svg"
                  alt="Gradient OSK logo"
                />
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }

  return null;
};
