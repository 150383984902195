import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { SliceZone, useSinglePrismicDocument, usePrismicDocumentByUID, PrismicRichText } from "@prismicio/react";
import * as prismicH from "@prismicio/helpers";
import { Link } from "react-router-dom";
import {Helmet} from "react-helmet";

import { components } from "../slices";
import { Layout } from "../components/Layout";
import { NotFound } from "./NotFound";

import { PostDate } from "../components/PostList/PostDate";

/**
 * Blog post page component
 */
export const Post = () => {
  const { uid } = useParams();
  const [prismicDoc, prismicDocState] = usePrismicDocumentByUID("post", uid);
  const [menu, menuState] = useSinglePrismicDocument("menu");
  const [footermenu, footermenuState] = useSinglePrismicDocument("footer_menu");

  const notFound = prismicDocState.state === "failed";

  useEffect(() => {
    if (prismicDocState.state === "failed") {
      console.warn(
        "Blog post document was not found. Make sure it exists in your Prismic repository"
      );
    }
  }, [prismicDocState.state]);

  // Return the page if a document was retrieved from Prismic
  if (prismicDoc && menu && footermenu) {
    const title = prismicH.asText(prismicDoc.data.title) || "Untitled";

    return (
      <Layout wrapperClass="post" seoTitle={title} menuDoc={menu} footerDoc={footermenu}>
        <Helmet>
          <title data-react-helmet="true">{((prismicDoc.data.meta_title || {})[0] || {}).text} - Orbital Sidekick</title>
          <meta
            name="description"
            content={((prismicDoc.data.meta_desc || {})[0] || {}).text}
            data-react-helmet="true"
          />
          <meta
            property="og:title"
            content={`${((prismicDoc.data.meta_title || {})[0] || {}).text}`}
            data-react-helmet="true"
          />
          <meta
            property="og:description"
            content={((prismicDoc.data.meta_desc || {})[0] || {}).text}
            data-react-helmet="true"
          />
          <link
            rel="canonical"
            href={`https://www.orbitalsidekick.com/news-blog/${uid}`}
            data-react-helmet="true"
          />
        </Helmet>
        <section className="lead--in">
          <div className="container">
            <div className="row">
              <div className="col pb-5">
                <Link className="back--link" to="/news-blog">&lt; Back to All Publications</Link>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <h3 className="subtitle">
                  <PostDate date={prismicDoc.data.date} />
                </h3>
              </div>
            </div>
            <div className="row">
              <div className="col-md-10">
                <h1>{title}</h1>
              </div>
            </div>
          </div>
        </section>
        <section className="post--body">
          <div className="container">
            <div className="row">
              <div className="col-md-8 offset-md-2">
                <PrismicRichText field={prismicDoc.data.content} />
              </div>
            </div>
          </div>
        </section>
        <SliceZone slices={prismicDoc.data.body} components={components} />
      </Layout>
    );
  } else if (notFound) {
    return <NotFound />;
  }

  return null;
};
