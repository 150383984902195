/**
 * Full width image slice component
 */
export const FullWidthImage = ({ slice }) => (
  <section className="full-width-image content-section">
    <div className="container">
      <div className="row">
        <div className="col">
          <img className="img-fluid" src={slice.primary.image.url} alt={slice.primary.image.alt} />
        </div>
      </div>
    </div>
  </section>
);
