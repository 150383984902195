import { PrismicText, PrismicRichText, PrismicLink } from "@prismicio/react";

/**
 * List items slice component
 */
export const ListItems = ({ slice }) => (
  <section className="list-items content-section">
    <div className="container">
      <div className="row pb-5">
        <div className="col">
          <h3 className="subtitle mb-5">
            <PrismicText field={slice.primary.subtitle} />
          </h3>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <PrismicRichText field={slice.primary.bulletpoints} />
        </div>
      </div>
      <div className="row mt-5">
        <div className="col text-center mb-5">
          <PrismicLink field={slice.primary.primary_cta_link} className="btn btn-secondary">
            <PrismicText field={slice.primary.primary_cta_label} />
          </PrismicLink>
          <hr />
        </div>
      </div>
    </div>
  </section>
);
