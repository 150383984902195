import { PrismicText, PrismicRichText } from "@prismicio/react";
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';

/**
 * Image Comparison slice component
 */
export const ImageComparison = ({ slice }) => {
  const sectionClass = slice.slice_label
    ? `text-section-${slice.slice_label}`
    : "right-text-section";
  const sectionBg = slice.primary.alt_bg;

  const img1 = slice.primary.image_1.url;
  const img2 = slice.primary.image_2.url;

  return (
    <section className={`content-section ${sectionClass} alt--bg_${sectionBg}`}>
      <div className="container">
        <div className="row align-items-center py-5">
        <div className="col-md-5">
          <ReactCompareSlider
            itemOne={<ReactCompareSliderImage src={img1} alt="Image one" />}
            itemTwo={<ReactCompareSliderImage src={img2} alt="Image two" />}
          />
          <div className="caption pt-3">
            <PrismicText field={slice.primary.caption} />
          </div>
        </div>
          <div className="col-md-6 offset-md-1">
            <PrismicRichText field={slice.primary.right_text} />
          </div>
        </div>
      </div>
    </section>
  );
};
