import { PrismicText, PrismicLink } from "@prismicio/react";

/**
 * Header banner component
 */
export const Banner = ({ banner }) => (
  <section
    className={`lead--in ${ banner.header_image.url && ("has--image")}`}
    style={{
      backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.4)), url(${banner.header_image.url})`,
    }}
  >
    <div className="container">
      <div className="row">
        <div className="col-md-10">
          {
            banner.subtitle && (
              <h3 className="subtitle mb-5 no--border">
                <PrismicText field={banner.subtitle} />
              </h3>
            )
          }
          <h1 className="banner-title">
            <PrismicText field={banner.page_title} />
          </h1>
          <p className="banner-description my-5">
            <PrismicText field={banner.tagline} />
          </p>
          <PrismicLink field={banner.primary_cta_link} className="btn btn-primary">
            <PrismicText field={banner.primary_cta_label} />
          </PrismicLink>
          <PrismicLink field={banner.secondary_cta_link} className="btn btn-primary ms-5">
            <PrismicText field={banner.secondary_cta_label} />
          </PrismicLink>
        </div>
      </div>
    </div>
  </section>
);
