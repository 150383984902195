import { useEffect } from "react";
import { useParams } from "react-router-dom";
import {Helmet} from "react-helmet";
import {
  SliceZone,
  usePrismicDocumentByUID,
  useSinglePrismicDocument,
} from "@prismicio/react";

import { components } from "../slices";
import { Layout } from "../components/Layout";
import { Banner } from "../components/Banner";
import { NotFound } from "./NotFound";

/**
 * Website page component
 */
export const Page = () => {
  const { uid } = useParams();

  const [page, pageState] = usePrismicDocumentByUID("page", uid);
  const [menu, menuState] = useSinglePrismicDocument("menu");
  const [footermenu, footermenuState] = useSinglePrismicDocument("footer_menu");

  const notFound = pageState.state === "failed" || menuState.state === "failed" || footermenuState.state === "failed";

  useEffect(() => {
    if (pageState.state === "failed") {
      console.warn(
        "Page document was not found. Make sure it exists in your Prismic repository"
      );
    }
  }, []);

  // Return the page if a document was retrieved from Prismic
  if (page && menu && footermenu) {
    return (
      <Layout wrapperClass={`page page--${uid}`} menuDoc={menu} footerDoc={footermenu}>
        <Helmet>
          <title data-react-helmet="true">{((page.data.meta_title || {})[0] || {}).text} - Orbital Sidekick</title>
          <meta
            name="description"
            content={((page.data.meta_desc || {})[0] || {}).text}
            data-react-helmet="true"
          />
          <meta
            property="og:title"
            content={`${((page.data.meta_title || {})[0] || {}).text}`}
            data-react-helmet="true"
          />
          <meta
            property="og:description"
            content={((page.data.meta_desc || {})[0] || {}).text}
            data-react-helmet="true"
          />
          <link
            rel="canonical"
            href={`https://www.orbitalsidekick.com/${uid}`}
            data-react-helmet="true"
          />
        </Helmet>
        <Banner banner={page.data.banner[0]} />
        <SliceZone slices={page.data.page_content} components={components} />
      </Layout>
    );
  } else if (notFound) {
    return <NotFound />;
  }

  return null;
};
