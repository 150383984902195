import { PrismicText, PrismicLink } from "@prismicio/react";

/**
  * Featured list item
*/
const Link = ({ item }) => {
  return (
    <div className="col-md-4">
      <PrismicLink field={item.link_url} className="block--link mb-5">
        <h6>
          <PrismicText field={item.link_title} />
        </h6>
        <span>
          <PrismicText field={item.link_subtitle} />
        </span>
      </PrismicLink>
    </div>
  );
};

/**
 * Link list slice component
 */
export const LinkList = ({ slice }) => (
  <section className="numbered-list-items content-section py-5">
    <div className="container">
      <div className="row">
        {slice.items.map((item) => (
        <Link key={item.link_title} item={item} />
        ))}
      </div>
    </div>
  </section>
);
