import { PrismicText, PrismicRichText } from "@prismicio/react";

/**
 * Text section slice component
 */
export const RightTextSection = ({ slice }) => {
  const sectionClass = slice.slice_label
    ? `text-section-${slice.slice_label}`
    : "right-text-section";

  return (
    <section className={`content-section ${sectionClass}`}>
      <div className="container">
        <div className="row align-items-center py-5">
        <div className="col-md-5">
          <img className="img-fluid" src={slice.primary.left_image.url} alt={slice.primary.left_image.alt} />
        </div>
          <div className="col-md-6 offset-md-1 pt-5">
            <PrismicRichText field={slice.primary.right_copy} />
          </div>
        </div>
      </div>
    </section>
  );
};
