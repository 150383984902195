import { useEffect } from "react";
import { useParams } from "react-router-dom";
import {Helmet} from "react-helmet";
import { useSinglePrismicDocument, usePrismicDocumentsByType, PrismicText, PrismicRichText, PrismicLink } from "@prismicio/react";

import { Layout } from "../components/Layout";
import { Banner } from "../components/Banner";
import { PostList } from "../components/PostList";
import { NotFound } from "./NotFound";

/**
 * Website page component
 */
export const BlogLanding = () => {

  const [blog, blogState] = useSinglePrismicDocument("blog_landing");
  const [menu, menuState] = useSinglePrismicDocument("menu");
  const [footermenu, footermenuState] = useSinglePrismicDocument("footer_menu");
  const [blogPosts, blogPostsState] = usePrismicDocumentsByType("post", {
    orderings: [{ field: "my.post.date", direction: "desc" }],
  });

  const notFound = blogState.state === "failed" || menuState.state === "failed" || footermenuState.state === "failed" || blogPostsState.state === "failed";

  useEffect(() => {
    if (blogState.state === "failed") {
      console.warn(
        "Page document was not found. Make sure it exists in your Prismic repository"
      );
    }
  }, []);

  // Return the blog if a document was retrieved from Prismic
  if (blog && menu && footermenu && blogPosts?.results) {
    const featImg = blog.data.featured_image.url;
    return (
      <Layout wrapperClass="blog" menuDoc={menu} footerDoc={footermenu}>
        <Helmet>
          <title data-react-helmet="true">{((blog.data.meta_title || {})[0] || {}).text} - Orbital Sidekick</title>
          <meta
            name="description"
            content={((blog.data.meta_desc || {})[0] || {}).text}
            data-react-helmet="true"
          />
          <meta
            property="og:title"
            content={`${((blog.data.meta_title || {})[0] || {}).text}`}
            data-react-helmet="true"
          />
          <meta
            property="og:description"
            content={((blog.data.meta_desc || {})[0] || {}).text}
            data-react-helmet="true"
          />
          <link
            rel="canonical"
            href={`https://www.orbitalsidekick.com/news-blog`}
            data-react-helmet="true"
          />
        </Helmet>
        <section className="lead--in">
          <div className="container">
            <div className="row">
              <div className="col-md-10">
                {
                  blog.data.subtitle && (
                    <h3 className="subtitle mb-5 no--border">
                      <PrismicText field={blog.data.subtitle} />
                    </h3>
                  )
                }
                <h1 className="banner-title">
                  <PrismicText field={blog.data.title} />
                </h1>
              </div>
            </div>
          </div>
        </section>

        <section className="container">
          <div className="row">
            <div className="col">
              <h3 className="subtitle mb-5">
                <PrismicText field={blog.data.featured_subtitle} />
              </h3>
            </div>
          </div>
          <div className="row align-items-center py-5">
            <div className="col-md-6">
              {
                featImg ? (
                <figure
                  className="featured--img"
                  style={{
                    backgroundImage: `url(${featImg})`,
                  }}>
                </figure>
                ):
                <div className="embed-container">
                  <iframe src={`${((blog.data.featured_video_url || {})[0] || {}).text}`} frameborder="0" webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe>
                </div>
              }
            </div>
            <div className="col-md-5 offset-md-1">
              <h5 className="mb-5">
                <PrismicText field={blog.data.featured_title} />
              </h5>
              <PrismicRichText field={blog.data.featured_description} />
            </div>
          </div>
        </section>

        <section className="container mt-5">
          <div className="row">
            <div className="col">
              <h3 className="subtitle mb-5">
                <PrismicText field={blog.data.secondary_subtitle} />
              </h3>
            </div>
          </div>
        </section>
        <PostList posts={blogPosts?.results} />
        <section className="content-section text-section-footer-block mt-5">
          <div className="container mt-5">
            <div className="row">
              <div className="col">
                <h3 className="subtitle mb-5">
                  <PrismicText field={blog.data.footer_block_subtitle} />
                </h3>
              </div>
            </div>
            <div className="row">
              <div className="col-md-8">
                <PrismicRichText field={blog.data.footer_block_copy} />
              </div>
            </div>
            <div className="row py-5">
              <div className="col py-5">
                <PrismicLink field={blog.data.footer_block_button_link} className="btn btn-primary btn-xxl btn-block">
                  <PrismicText field={blog.data.footer_block_button_label} />
                </PrismicLink>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    );
  } else if (notFound) {
    return <NotFound />;
  }

  return null;
};
