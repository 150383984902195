import { PrismicText, PrismicRichText } from "@prismicio/react";

/**
  * Featured list item
*/
const Featured = ({ item }) => {
  return (
    <li>
      <PrismicRichText field={item.title} />
      <span></span>
      <PrismicRichText field={item.description} />
    </li>
  );
};

/**
 * Numbered items slice component
 */
export const NumberedItems = ({ slice }) => (
  <section className="numbered-list-items content-section alt--bg_true">
    <div className="container">
      <div className="row pt-5">
        <div className="col">
          <h3 className="subtitle mb-5">
            <PrismicText field={slice.primary.subtitle} />
          </h3>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <ol>
            {slice.items.map((item) => (
            <Featured key={item.id} item={item} />
            ))}
          </ol>
        </div>
      </div>
    </div>
  </section>
);
