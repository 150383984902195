import { PrismicText, PrismicRichText, PrismicLink } from "@prismicio/react";

/**
  * Featured logos
*/
const FeatureLogos = ({ item }) => {
  return (
    <img className="feature--logo img-fluid" src={item.logo.url} alt={item.logo.alt} />
  );
};

/**
 * Logos slice component
*/
export const Logos = ({ slice }) => {
  const sectionClass = slice.slice_label
    ? `text-section-${slice.slice_label}`
    : "img-section-logos";

  return (
    <section className={`content-section ${sectionClass}`}>
      <div className="container mt-5">
        <div className="row pt-5">
          <div className="col">
            <h3 className="subtitle mb-5">
              <PrismicText field={slice.primary.subtitle} />
            </h3>
          </div>
        </div>
        <div className="row align-items-center py-5">
          <div className="col marquee--wrapper">
            <div className="marquee--left"></div>
            <div className="marquee--right"></div>
            <div className="marquee--slider">
              {slice.items.map((item) => (
              <FeatureLogos key={item.logo.url} item={item} />
              ))}
              {slice.items.map((item) => (
              <FeatureLogos key={item.logo.url} item={item} />
              ))}
              {slice.items.map((item) => (
              <FeatureLogos key={item.logo.url} item={item} />
              ))}
            </div>
          </div>
        </div>
        <div className="row py-5 border-t">
          <div className="col text-center mt-3">
            <PrismicLink field={slice.primary.primary_button_link} className="btn btn-secondary mb-3 mb-md-0">
              <PrismicText field={slice.primary.primary_button_label} />
            </PrismicLink>
            <PrismicLink field={slice.primary.secondary_button_link} className="btn btn-secondary ms-md-5">
              <PrismicText field={slice.primary.secondary_button_label} />
            </PrismicLink>
          </div>
        </div>
      </div>
    </section>
  );
};
