import { PrismicRichText, PrismicLink, PrismicText } from "@prismicio/react";

/**
 * Dual image slice component
 */
export const DualImage = ({ slice }) => (
  <section className="dual-image content-section">
    <div className="container">
      <div className="row">
        <div className="col-md-6">
          <img className="img-fluid mb-5" src={slice.primary.left_image.url} alt={slice.primary.left_image.alt} />
          <PrismicRichText field={slice.primary.left_copy} />
        </div>
        <div className="col-md-6">
          <img className="img-fluid mb-5" src={slice.primary.right_image.url} alt={slice.primary.right_image.alt} />
          <PrismicRichText field={slice.primary.right_copy} />
        </div>
      </div>
      <div className="row py-5">
        <div className="col text-center">
          <PrismicLink field={slice.primary.primary_cta_link} className="btn btn-secondary">
            <PrismicText field={slice.primary.primary_cta_label} />
          </PrismicLink>
        </div>
      </div>
    </div>
  </section>
);
