import { PrismicText } from "@prismicio/react";


/**
 * Heading
 */
export const Heading = ({ slice }) => (
  <section className="heading alt--bg_true pt-5 content-section">
    <div className="container">
      <div className="row">
        <div className="col">
          <h3 className="m-0">
            <PrismicText field={slice.primary.title} />
          </h3>
        </div>
      </div>
    </div>
  </section>
);
