import { PrismicText, PrismicRichText, PrismicLink } from "@prismicio/react";

/**
  * Featured list item
*/
const Person = ({ item }) => {
  return (
    <div className="col-md-4 mb-3">
      <div className="row">
        <div className="col-4 col-md-12">
          <figure
            style={{
              backgroundImage: `url(${item.headshot.url})`,
            }}
          >
          </figure>
        </div>
        <div className="col-8 col-md-12">
          <PrismicRichText field={item.name} />
          <PrismicRichText field={item.title} />
        </div>
      </div>
    </div>
  );
};

/**
 * Team members slice component
 */
export const TeamMembers = ({ slice }) => (
  <section className="team-members content-section">
    <div className="container">
      <div className="row py-5">
        <div className="col">
          <h3 className="subtitle mb-5">
            <PrismicText field={slice.primary.subtitle} />
          </h3>
        </div>
      </div>
      <div className="row">
        {slice.items.map((item) => (
        <Person key={item.headshot.url} item={item} />
        ))}
      </div>
      <div className="row pt-5">
        <div className="col text-center">
          <PrismicLink href="/careers" className="btn btn-secondary">
            Join Us
          </PrismicLink>
        </div>
      </div>
    </div>
  </section>
);
