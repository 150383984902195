import { PostItem } from "./PostItem";

/**
 * Post list component
 */
export const PostList = ({ posts }) => {
  return (
    <div className="container">
      <div className="row mb-5">
        {posts.map((post) => (
          <PostItem post={post} key={post.id} />
        ))}
      </div>
    </div>
  );
};
